import './App.css';
import { useContext } from "react";
import AuthRouter from './routers/AuthRouter';
import UnauthRouter from './routers/UnauthRouter';
import AuthContext from './context/AuthContext';

function App() {
  const {auth} = useContext(AuthContext)
  return (
    <div className="App">
        {auth === true? <AuthRouter/>: <UnauthRouter/>}
    </div>
  );
}

export default App;
