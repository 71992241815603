import { useEffect, useReducer, useState } from "react";
import ChallengeList from "../components/ChallengeList";
import StackblitzCode from "../components/StackblitzCode";
import "../../style/Hacks.css";
import { httpService } from "../../services/httpService";
import { apiHackers, apiSolution, apiSolutionAccepted, root } from "../../services/api";
import ChallengeDesc from "../components/ChallengeDesc";
import MessageModal from "../components/MessageModal";
import { Challenge } from "../../models/Challenge";
import { FormJoinChallenge } from "../components/FormJoinChallenge";
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Form from 'react-bootstrap/Form'
import Ranking from "../components/Ranking";
import { rankingInitialState, rankingReducer } from "../../reducers/rankingReducer";
import { TYPES } from "../../actions/rankingActions";
import Button from "react-bootstrap/Button";
import Alert from 'react-bootstrap/Alert'

const objSolution = {
  challenge: null,
  code_files: null
}

const Hacks = () => {
  const [solveChallenge, setSolveChallenge] = useState(Challenge);
  const [stateRanking, dispatch] = useReducer(rankingReducer, rankingInitialState);
  const { hackersView } = stateRanking;
  const [idChallengeHacked, setIdChallengeHacked] = useState();
  const [idChallengeCreated, setIdChallengeCreated] = useState();
  const [tabHacked, setTabHacked] = useState(true);
  const [solution, setSolution] = useState(objSolution);
  const [modal, setModal] = useState({ show: false, title: "", body: "" });

  useEffect(() => {
    if (hackersView.length > 0) {
      getSolutionByHacker(hackersView[0]._id);
    }
  }, [hackersView])


  const handleCloseModal = () => setModal({ show: false, title: "", body: "" });

  const handleCode = (projectCode) => {
    httpService()
      .post(`${root}${apiSolution}`, {
        body: {
          id_challenge: solveChallenge._id,
          code_files: projectCode,
        },
      })
      .then((resp) => {
        if (resp.created) {
          setModal({
            title: "Hack sent",
            body: "Your solution has been sent",
            show: true
          });
          setSolveChallenge(Challenge);
        } else {
          setModal({
            show: true,
            title: "TRY LATER",
            body: "An error occurred on the server",
          });
        }
      })
      .catch((error) => {
        setModal({
          show: true,
          title: "TRY LATER",
          body: "An error occurred on the server",
        });
      });
  };

  //-------------------------
  const callbackSelectChallenge = async (challenge, loadCreated) => {
    loadCreated ? setIdChallengeCreated(challenge._id) : setIdChallengeHacked(challenge._id)
    if (!loadCreated === tabHacked) {
      loadHackersView(challenge._id);
      setSolveChallenge(challenge);
    }
  }

  const loadHackersView = async (idChallenge) => {
    let resp = await httpService().get(`${root}${apiHackers}/${idChallenge}`);
    let payload = { idChallenge, hackers: resp['hackers'] };
    dispatch({ type: TYPES.ADD_RANKING, payload: payload });
  }

  const handleTabSelect = (key) => {
    if (key === 'hacked') {
      setTabHacked(true);
      loadHackersView(idChallengeHacked);
    } else {
      setTabHacked(false);
      loadHackersView(idChallengeCreated);
    }
    if (hackersView.length > 0) {
      getSolutionByHacker(hackersView[0]._id);
    }
  }

  const getSolutionByHacker = (idHacker) => {
    let idChallenge = tabHacked ? idChallengeHacked : idChallengeCreated;
    httpService().get(`${root}${apiSolution}/${idHacker}/${idChallenge}`).then(resp => {
      console.log("solution resp-> ", resp);
      if (resp['solution']) {
        let solution = resp['solution']
        setSolution(solution)
      } else {
        setSolution(objSolution);
      }
    });
  }

  const callbackJoinChallenge = (challenge) => {
    console.log(challenge)
    setSolveChallenge({ ...challenge, show: true })
  }

  const checkSolution = (accepted) => {
    console.log("Id solution-> ", solution._id)
    httpService()
      .put(`${root}${apiSolutionAccepted}`, {
        body: {
          id: solution._id,
          accepted_solution: accepted,
        },
      })
      .then((resp) => {
        setSolution({ ...solution, accepted_solution: accepted })
      })
      .catch((error) => {

      });
  }

  return (
    <div id="hacks">
      {!solveChallenge.show && <div id="menuContainer">
        {/**div container the 'Challenges' section */}
        <div id="challengeMenu">
          <br />
          <h3 id="title"> Challenges </h3>{" "}
          <br />
          <Tabs
            defaultActiveKey="hacked"
            transition={true}
            id="tab-challenges"
            className="mb-3"
            onSelect={(key) => handleTabSelect(key)}
          >
            <Tab eventKey="hacked" title="hacked" key="hacked">
              <br />
              <div className="contInput">
                <Form.Control type="text" placeholder="Search..." />
              </div>
              <br />
              <ChallengeList callbackSelectChallenge={callbackSelectChallenge} loadCreated={false} />
            </Tab>
            <Tab eventKey="created" title="created" key="created">
              <br />
              <div className="contInput">
                <Form.Control cal type="text" placeholder="Search..." />
              </div>
              <br />
              <ChallengeList callbackSelectChallenge={callbackSelectChallenge} loadCreated={true} />
            </Tab>
          </Tabs>
        </div>
        <div id="hacksContainer">
          {/**Input component to join a new challenge*/}
          <FormJoinChallenge callbackJoinChallenge={callbackJoinChallenge} />
          {/**div container the 'Hackers' section */}
          <div id="hackersMenu">
            <Ranking hackersView={hackersView} callbackHackerSelected={getSolutionByHacker} />
          </div>
        </div>
      </div>}
      <div id="contDescAndCode">
        <div id="contDescription">
          {
            solveChallenge.title.length > 0 && <ChallengeDesc
              title={solveChallenge.title}
              description={solveChallenge.description}
              schedule_time={solveChallenge.schedule_time}
              created_at={solveChallenge.created_at}
              challengeHacked={!solveChallenge.show}
            />
          }
        </div>
        <div>
          {
            !tabHacked && solution.accepted_solution === null && <>
              <Button variant="danger" onClick={() => checkSolution(false)}>
                Bad
              </Button>
              &nbsp;
              <Button variant="success" onClick={() => checkSolution(true)}>
                Good
              </Button>
            </>
          }
        </div>
        {
            solution.accepted_solution === false && <>
              <Alert variant='danger'>
                The solution does not meet the expected requirements
              </Alert>

            </>
          }
        {/*-----IDE----*/}
        <StackblitzCode
          className="ide"
          projectFiles={solveChallenge.show ? solveChallenge.code_files : solution.code_files}
          handleCode={solveChallenge.show ? handleCode : null}
          createChallenge={false}
        />
      </div>
      <MessageModal
        title={modal.title}
        body={modal.body}
        show={modal.show}
        handleClose={handleCloseModal}
      />
    </div>
  );
};

export default Hacks;
