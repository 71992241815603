import { useEffect } from "react";
import { useState } from "react";
import moment from "moment";
import "./components.css";

export default function ChallengeDesc({ title, description, schedule_time, created_at, challengeHacked }) {

  const [timer, setTimer] = useState({ hour: 0, min: 0, seg: 0 });
  const [useInterval, setUseInterval] = useState();

  useEffect(() => {
    clearInterval(useInterval);
  }, [challengeHacked])

  useEffect(() => {
    if (!challengeHacked) {
      //Get hour - minutes - seconds
      let array_time = schedule_time.split(":");
      let h = parseInt(array_time[0]);
      let m = parseInt(array_time[1]);
      let s = parseInt(array_time[2]);
      //Create and set object Date
      let deadline = new Date(created_at);
      deadline.setHours(deadline.getHours() + h);
      deadline.setMinutes(deadline.getMinutes() + m);
      deadline.setSeconds(deadline.getSeconds() + s);
      //Create object Date with current date and time
      let now = new Date();

      //Compare dates
      if (deadline > now) {
        let a = moment(deadline.toISOString());
        let b = moment(now.toISOString());
        let diff = moment.duration(a.diff(b));
        h = diff.hours();
        m = diff.minutes();
        s = diff.seconds();
        startTimer(h, m, s);
      }
    }
  }, [schedule_time]);

  const startTimer = (h, m, s) => {
    let objInterval = setInterval(() => {
      if (s > 0) {
        s--;
      } else if (m > 0) {
        m--;
        s = 59;
      } else if (h > 0) {
        h--;
        m = 59;
        s = 59;
      } else {
        clearInterval(objInterval);
      }
      setTimer({ hour: h, min: m, seg: s });
    }, 1000);
    setUseInterval(objInterval)
  }


  return (
    <>
      <h5> {title} </h5>{" "}
      {(timer.hour > 0 || timer.min > 0 || timer.seg > 0) ?
        <h6 className="timer">
          {timer.hour < 10 ? `0${timer.hour}` : timer.hour}
          :{timer.min < 10 ? `0${timer.min}` : timer.min}
          :{timer.seg < 10 ? `0${timer.seg}` : timer.seg}
        </h6>
        :
        <></>
      }

      <p>
        {description}
      </p>{" "}
    </>
  );
}
