export const httpService = () => {

    const customFetch = (endpoint, options) => {
        const defaultHeader = {
            'Content-Type': 'application/json'
        }
        //Objeto para abortar la conexión cuando el servidor no responde
        const controller = new AbortController();
        options.signal = controller.signal;

        options.method = options.method || "GET";
        options.headers = options.headers ? {
            ...defaultHeader,
            ...options.headers
        } : defaultHeader;

        options.credentials = 'include'

        options.body = JSON.stringify(options.body) || false;
        //'delete' permite eliminar una propiedad de un objeto en js
        if (!options.body) delete options.body;

        //Si después de 10 segundos no responde la petición entonces la cancelo
        setTimeout(() => controller.abort(), 10000);

        return fetch(endpoint, options).then(resp => resp.ok ? resp.json() : Promise.reject({
            err: true,
            status: resp.status || "00",
            statusText: resp.statusText || "Ocurrió un error"
        })).catch(error => error);;
    }

    const get = (url, options = {}) => customFetch(url, options);

    const post = (url, options = {}) => {
        options.method = "POST";
        return customFetch(url, options);
    }

    const put = (url, options = {}) => {
        options.method = "PUT";
        return customFetch(url, options);
    }
    const del = (url, options = {}) => {
        options.method = "DELETE";
        return customFetch(url, options);
    }
    
    return {
        get,
        post,
        put,
        del,
    };
}
