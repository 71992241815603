import "../style/Login.css";
import avatar from "../assets/avatar.png";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import GoogleLogin from 'react-google-login';

const Login = () => {
  const { handleAuth } = useContext(AuthContext);

  return (
    <div className="App_login">
      <h2>Debnix</h2>
      <img src={avatar} id="avatar" alt="avatar" />
      <div className="container">
        <section id="sectionLogin">
          <h3>Log in with</h3>
          {/*-----Inicio de sesión con google-----*/}
          <GoogleLogin
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            buttonText="Google"
            onSuccess={handleAuth}
            onFailure={handleAuth}
            cookiePolicy={'single_host_origin'}
          />
        </section>
      </div>

      {/**************************************************************/}
    </div>
  );
};

export default Login;
