import React, { useContext } from 'react'
import Spinner from 'react-bootstrap/Spinner'
import AuthContext from '../context/AuthContext'
import Login from './Login'

const VerifyAuth = () => {
    const { auth, verifiedAuth } = useContext(AuthContext)

    return (
        <>
            {!auth && verifiedAuth === true
                ? <Login />
                :
                <div style={{ marginTop: '30vh', paddingLeft: '20%', paddingRight: '20%' }}>
                    <span style={{ fontSize: '8rem' }}>Loading</span>
                    <Spinner animation="grow" size='xl' />
                    <Spinner animation="grow" size='xl' />
                    <Spinner animation="grow" size='xl' />
                    <br />
                </div>}
        </>

    )
}

export default VerifyAuth