import React from "react";
import { Modal, Button } from "react-bootstrap";
import "./components.css";

const ModalChallenge = ({ show, handleClose, code }) => {
  return (
    <Modal className="modalChallenge" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Challenge created</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{textAlign: 'center'}}>
        Share the following code to your hackers
        <br />
        <span id="codeChallenge">
          {code}
        </span>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-info" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalChallenge;
