import { Route, Routes } from "react-router-dom"
import NotFound from "../public/NotFound"
import VerifyAuth from "../public/VerifyAuth";

const UnauthRouter = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<VerifyAuth/>}/>
                <Route path="*" element={<NotFound />} />
            </Routes>
        </>
    )
}

export default UnauthRouter;