import ListGroup from "react-bootstrap/ListGroup";
import Tab from 'react-bootstrap/Tab';

export default function Ranking({ hackersView, callbackHackerSelected }) {

  const emoticons = (pos, end) => {
    if (pos === 0)
      return <>&#x1F3C6;</>
    else if (pos === 1)
      return <>&#127866;</>
    else if (pos === 2)
      return <>&#x1F576;</>
    else if (pos === end)
      return <>&#x1F37C;</>
  }

  return (
    <>
      <Tab.Container id="list-group-tabs-ranking" defaultActiveKey="#0">
        <h3 style={{ color: 'black' }}>Hackers</h3>
        <ListGroup as="ol" numbered>
          {hackersView.length > 0 ?
            hackersView.map((hacker, pos) =>
              <ListGroup.Item className="pointer" action href={`#${pos}`} key={pos} variant="light" as="li" onClick={() => callbackHackerSelected(hacker._id)}>
                {emoticons(pos, hackersView.length - 1)}{hacker.name}
              </ListGroup.Item>
            )
            : 
            <ListGroup.Item>No hacker solved this challenge</ListGroup.Item>
          }
        </ListGroup>{" "}
        <br />
      </Tab.Container>{" "}
    </>
  );
}
