import { Route, Routes } from "react-router-dom";
import CreateChallenge from "../private/pages/CreateChallenge";
import Dashboard from "../private/pages/Dashboard";
import Hacks from "../private/pages/Hacks";

const AuthRouter = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Dashboard />}>
          <Route index element={<CreateChallenge />} /> {" "}
          <Route path="hacks" element={<Hacks />} />
          
          {/*}<Route path="*" element={<NotFound/>}></Route>{*/}{" "}
        </Route>
      </Routes>{" "}
    </>
  );
};

export default AuthRouter;
