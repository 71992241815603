import ListGroup from "react-bootstrap/ListGroup";
import Tab from "react-bootstrap/Tab";
import { httpService } from "../../services/httpService";
import { root, challenge, apiSolution } from "../../services/api";
import { useEffect, useState } from "react";

export default function ChallengeList({ callbackSelectChallenge, loadCreated }) {

  const [challenges, setChallenges] = useState([]);

  useEffect(() => {
    let endPoint = loadCreated ? challenge : apiSolution;
    console.log(`endPoint: ${endPoint}`)
    httpService()
      .get(root + endPoint)
      .then((resp) => {
        if(resp.length > 0){
          callbackSelectChallenge(resp[0], loadCreated);
          setChallenges(resp);
        }
      });
  }, []);


  return (
    <>
      <Tab.Container id="list-group-tabs-challenge" defaultActiveKey="#0">
        <ListGroup as="ol">
          {" "}
          {challenges.map((e, i) => {
            return (
              <ListGroup.Item key={i} action href={`#${i}`} onClick={() => callbackSelectChallenge(e, loadCreated)} variant="dark" as="li">
                {e.title}{" "}
              </ListGroup.Item>
            );
          })}{" "}
        </ListGroup>{" "}
      </Tab.Container>
    </>
  );
}
