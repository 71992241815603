import { createContext, useEffect, useState } from "react";
import { root, apiAuth, apiVerifyLoggin, apiLogout } from "../services/api";
import { httpService } from "../services/httpService";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(false);
  const [verifiedAuth, setVerifiedAuth] = useState(false);
  useEffect(() => {
    httpService().get(`${root}${apiVerifyLoggin}`).then((resp) => {
      if (!resp.err) {
        if (resp.loggedIn) {
          setAuth(resp.loggedIn);
        }
        setVerifiedAuth(true);
      }
    });
  }, []);

  const handleAuth = (googleData) => {
    let token = googleData.tokenId;
    httpService()
      .post(`${root}${apiAuth}`, {
        body: { token }
      })
      .then((resp) => {
        if (!resp.err) {
          setAuth(true);
        }
      });
  };

  const handleLogout = ()=>{
    httpService()
      .post(`${root}${apiLogout}`)
      .then((resp) => {
        if (!resp.err) {
          setAuth(false);
        }
      });
  }

  const data = {
    auth,
    verifiedAuth,
    handleAuth,
    handleLogout
  };
  return <AuthContext.Provider value={data}> {children} </AuthContext.Provider>;
};

export { AuthProvider };
export default AuthContext;
