import { TYPES } from "../actions/rankingActions";

/**
 * Estructura de los objetos que almacenará el array 'ranking':
 * {
 * idChallenge: string,
 * hackers: []
 * } 
*/
export const rankingInitialState = {
    ranking: [],
    hackersView: [],
}

export function rankingReducer(state, actions){
    switch(actions.type){
        case TYPES.ADD_RANKING:{
            let itemInRanking = state.ranking.find(item=> item.idChallenge === actions.payload.idChallenge);
            return itemInRanking ? {
                ...state,
                ranking: state.ranking.map(e => e.idChallenge === actions.payload.idChallenge ?
                    {...e, hackers: actions.payload.hackers} : e),
                hackersView: [...itemInRanking.hackers]
            } : {
                ...state,
                ranking: [...state.ranking, {...actions.payload}],
                hackersView: [...actions.payload.hackers]
            }
        }
        case TYPES.SEARCH_RANKING:{
            let ranking = state.ranking.find(item => item.idChallenge === state.payload.idChallenge)
            return ranking ? {
                ...state,
                hackersView: [...ranking.hackers]
            }: {
                ...state.hackersView
            }
        }
        default:
            return state;
    }
}