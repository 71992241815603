import React, { useState } from 'react'
import { Form, InputGroup, Button, FormControl, Alert } from "react-bootstrap";
import { httpService } from "../../services/httpService";
import { challenge, root } from "../../services/api";
import { AlertModel } from '../../models/Alert';
import "../../style/FormJoinChallenge.css";


export const FormJoinChallenge = ({callbackJoinChallenge}) => {
    const [form, setForm] = useState({ challenge_code: "" });
    const [alert, setShow] = useState(AlertModel);

    const handleForm = (e) =>
        setForm({ ...form, [e.target.name]: e.target.value });

    const handleSubmit = (e) => {
        e.preventDefault();
        httpService()
            .get(`${root}${challenge}${form.challenge_code}`)
            .then((resp) => {
                if (resp.available) {
                    //hide alert
                    setShow(AlertModel);
                    //setForm
                    setForm({ challenge_code: "" });
                    //show ide
                    callbackJoinChallenge(resp.challenge)
                } else if (resp.exists === false) {
                    setShow({
                        show: true,
                        variant: "warning",
                        msg: "The challenge does not exist",
                    });
                    setTimeout(() => {
                        setShow({
                            show: false,
                            variant: "",
                            msg: "",
                        })
                    }, 3000)
                } else {
                    setShow({
                        show: true,
                        variant: "danger",
                        msg: "The challenge has expired",
                    });
                    setTimeout(() => {
                        setShow({
                            show: false,
                            variant: "",
                            msg: "",
                        })
                    }, 3000)
                }
                console.log(resp);
            });
    };

    return (
        <div>
            <br />
            <h4>Hacks</h4>
            <div className="content">
                <Alert show={alert.show} variant={alert.variant}>
                    {alert.msg}
                </Alert>
                {/*-----Room code container----*/}
                <div className="divCodeRoom content">
                    <h5>Join a challenge</h5>
                    <Form style={{ width: "120px" }} onSubmit={handleSubmit}>
                        <InputGroup className="mb-3">
                            <FormControl
                                value={form.challenge_code}
                                onChange={handleForm}
                                name="challenge_code"
                                placeholder="room code"
                            />
                        </InputGroup>
                        <Button variant="outline-info" type="submit">
                            Join me
                        </Button>
                    </Form>
                </div>
            </div>
        </div>
    )
}
