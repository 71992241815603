import { Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import StackblitzCode from "../components/StackblitzCode";
import { challengeForm } from "../../models/ChallengeForm";
import "../../style/CreateChallenge.css";
import { httpService } from "../../services/httpService";
import Alert from "react-bootstrap/Alert";
import {root, challenge} from "../../services/api";
import ModalChallenge from "../components/ModalChallenge";

export default function CreateChallenge() {
  //states
  const [addCode, setAddCode] = useState(false);
  const [form, setForm] = useState(challengeForm);
  const [error, setError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [challengeCode, setChallengeCode] = useState("");

  const handleCloseModal = () => setShowModal(false);
  const handleCheckForm = (e) => setAddCode(e.target.checked);

  const handleForm = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const initialize = ()=>{
    setAddCode(false);
    setForm(challengeForm);
    setError(false);
  }

  const hanldeSubmit = (e) => {
    let objForm = {};
    if (e.preventDefault) {
      e.preventDefault();
      objForm = {
        ...form,
        schedule_time: `${form.hours}:${form.minutes}:${form.seconds}`,
      };
      delete objForm.code_files;
    } else {
      objForm = {
        ...form,
        code_files: e,
        schedule_time: `${form.hours}:${form.minutes}:${form.seconds}`,
      };
    }
    //Delete properties
    delete objForm.hours;
    delete objForm.minutes;
    delete objForm.seconds;
    httpService()
      .post(`${root}${challenge}`, {
        body: objForm,
      })
      .then(async(resp) => {
        console.log(resp);
        if (!resp.err) {
          setChallengeCode(resp.challenge_code);
          setShowModal(true);
          initialize();
        } else {
          setError(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setError(true);
      });
  };

  return (
    <>
      <h2> New Challenge </h2>{" "}
      <div>
        <Form onSubmit={hanldeSubmit}>
          <div className="contForm">
            <Form.Group className="mb-3" controlId="form.ControlInput1">
              <Form.Label> Title </Form.Label>{" "}
              <Form.Control
                type="text"
                placeholder="Challenge name"
                name="title"
                value={form.title}
                onChange={handleForm}
              />{" "}
            </Form.Group>{" "}
            <Form.Group className="mb-3" controlId="form.ControlTextarea1">
              <Form.Label> Description </Form.Label>{" "}
              <Form.Control
                as="textarea"
                rows={3}
                name="description"
                value={form.description}
                onChange={handleForm}
              />{" "}
            </Form.Group>{" "}
            <Form.Group className="mb-3" controlId="form.ControlInput2">
              <Form.Label> Timer </Form.Label>{" "}
              <Row>
                <Col lg="3" md="3" sm="3">
                  <Form.Label> Hours </Form.Label>{" "}
                  <Form.Control
                    type="number"
                    placeholder="Hour"
                    name="hour"
                    value={form.hours}
                    onChange={handleForm}
                  />{" "}
                </Col>{" "}
                <Col>
                  <span>: </span>{" "}
                </Col>{" "}
                <Col lg="3" md="3" sm="3">
                  <Form.Label> Minutes </Form.Label>{" "}
                  <Form.Control
                    type="number"
                    placeholder="Minutes"
                    name="minutes"
                    value={form.minutes}
                    onChange={handleForm}
                  />{" "}
                </Col>{" "}
                <Col>
                  <span>: </span>{" "}
                </Col>{" "}
                <Col lg="3" md="3" sm="3">
                  <Form.Label> Seconds </Form.Label>{" "}
                  <Form.Control
                    type="number"
                    placeholder="Seconds"
                    name="seconds"
                    value={form.seconds}
                    onChange={handleForm}
                  />{" "}
                </Col>{" "}
              </Row>{" "}
            </Form.Group>{" "}
            <Form.Check
              style={{
                textAlign: "left",
              }}
              type="switch"
              id="codeExample-switch"
              label="Add code base"
              checked={addCode}
              onChange={handleCheckForm}
            />{" "}
          </div>{" "}
          {addCode && (
            <div>
              <StackblitzCode
                handleCode={hanldeSubmit}
                createChallenge={true}
              />{" "}
            </div>
          )}{" "}
          <br />{" "}
          {!addCode && (
            <div>
              {error ? (
                <Alert className="alert" variant="danger">
                  Please try later
                </Alert>
              ) : (
                <></>
              )}{" "}
              <Button variant="success" type="submit">
                Start Challenge{" "}
              </Button>{" "}
            </div>
          )}{" "}
        </Form>{" "}
        {/*******Modal******/}
        <ModalChallenge show={showModal} handleClose={handleCloseModal} code={challengeCode}/>
      </div>{" "}
    </>
  );
}
