//Imports elements bootstrap
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import { Link, Outlet, useNavigate } from "react-router-dom";
import "../../style/Dashboard.css";
import Button from 'react-bootstrap/Button'
import { useContext } from "react";
import AuthContext from "../../context/AuthContext";

export default function Dashboard() {

  const {handleLogout} = useContext(AuthContext);
  const navigate = useNavigate()

  const handleBtnLogout = ()=>{
    navigate('/');
    handleLogout();
  }

  return (
    <>
      <Navbar className="darkContrast" variant="dark">
        <Container>
          <Navbar.Brand> D3bn1x </Navbar.Brand>{" "}
          <Nav className="me-auto" defaultActiveKey='#home'>
            <Nav.Link as={Link} to="hacks" href="#myhacks">Hacks</Nav.Link>
            <Nav.Link as={Link} to="/" href="#home">Create Challenge</Nav.Link>
          </Nav>
          <Button variant="outline-danger" onClick={handleBtnLogout}>Log out</Button>
        </Container>{" "}
      </Navbar>{" "}
      <div>
        <Outlet></Outlet>
      </div>
    </>
  );
}
