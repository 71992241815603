import { useState, useEffect } from "react";
import sdk from "@stackblitz/sdk";
import Button from "react-bootstrap/Button";

export default function StackblitzCode({
  handleCode,
  projectFiles,
  createChallenge
}) {
  const [objVm, setObjVm] = useState(null);

  //mount componente
  useEffect(() => {
    sdk
      .embedProject("myDiv", project, {
        height: "600px",
        forceEmbedLayout: true,
        view: createChallenge ? "editor" : "both",
      })
      .then((vm) => {
        setObjVm(vm);
      });
  }, []);

  //When change projectFiles
  useEffect(() => {
    sdk
      .embedProject("myDiv", project, {
        height: "600px",
        forceEmbedLayout: true,
        view: createChallenge ? "editor" : "both",
      })
      .then((vm) => {
        setObjVm(vm);
      });
  }, [projectFiles]);

  // Create the index.html file
  const codeIndexHtml = `<div id="root"></div>`;

  // Create the index.js file
  const codeIndexJs = `import React from "react";
  import ReactDOM from "react-dom";import App from "./App";
  ReactDOM.render(<App />, document.getElementById("root"));
  `;

  // Create the App.js file
  const codeAppJs = `import React from "react";
  export default function App() {
    return (
      <div>
      <h1>Hi Hacker!</h1>
      <p>Happy hacking!</p>
      </div>
      );
    }
    `;

  // Create the project payload.
  const project = {
    files: projectFiles || {
      "public/index.html": codeIndexHtml,
      "src/App.js": codeAppJs,
      "src/index.js": codeIndexJs,
    },
    title: "Proyecto configurado para ReactJs",
    description: "go! Mucha suerte",
    template: "create-react-app",
    tags: ["sdk"],
    dependencies: {
      react: "^17.0.2",
      "react-dom": "^17.0.2",
    },
  };

  const btnSend = (e) => {
    objVm.getFsSnapshot().then((projectCode) => {
      handleCode(projectCode);
    });
  };


  return (
    <>
      <div>
        {!createChallenge && handleCode &&
          <Button id="btnSendSolution" variant="success" onClick={btnSend}>
            Send solution
          </Button>
          }
      </div>

      <div id="myDiv"> </div>{" "}
      {createChallenge && (
        <Button variant="success" onClick={btnSend}>
          Start Challenge
        </Button>
      )}
    </>
  );
}
